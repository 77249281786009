import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/firebase";

function ResourceRequestForm() {
  const [technician, setTechnician] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const technicianId = searchParams.get("technicianId");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    urgency: "",
    description: "",
    numberOfResources: 1,
  });

  useEffect(() => {
    const fetchTechnician = async () => {
      if (technicianId) {
        const docRef = doc(db, "technicians", technicianId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setTechnician(docSnap.data());
        } else {
          console.error("No such document!");
        }
      }
    };

    fetchTechnician();
  }, [technicianId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === "numberOfResources" ? parseInt(value, 10) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    console.log("Form submitted:", { ...formData, technicianId });
    
    // Reset form
    setFormData({
      name: "",
      email: "",
      urgency: "",
      description: "",
      numberOfResources: 1,
    });

    // Redirect to Calendly
    window.location.href = "https://calendly.com/cam-sidekicksolutions/30min?month=2024-12";
  };

  return (
    <div className="min-h-screen w-full bg-white px-4 py-8">
      <button
        onClick={() => navigate('/')}
        className="mb-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600 shadow"
      >
        Back to Home
      </button>
      <h1 className="text-3xl font-bold mb-4 text-center text-blue-600">
        Request a Resource
      </h1>
      {technician && (
        <div className="mb-4 p-4 bg-white rounded-lg shadow max-w-md mx-auto">
          <h2 className="text-xl font-semibold mb-2 text-gray-800">Selected Technician</h2>
          <p className="text-gray-600">Name: {technician.firstName} {technician.lastName}</p>
          <p className="text-gray-600">Location: {technician.city}, {technician.state}</p>
          <p className="text-gray-600">Type: {technician.userType}</p>
        </div>
      )}
      <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white p-6 rounded-lg shadow">
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
          className="w-full mb-4 p-2 border border-gray-300 rounded text-gray-800"
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
          className="w-full mb-4 p-2 border border-gray-300 rounded text-gray-800"
        />
        <input
          type="text"
          name="urgency"
          placeholder="How quickly do you need these resources?"
          value={formData.urgency}
          onChange={handleChange}
          required
          className="w-full mb-4 p-2 border border-gray-300 rounded text-gray-800"
        />
        <div className="mb-4">
          <label htmlFor="numberOfResources" className="block text-sm font-medium text-gray-700 mb-1">
            Number of Resources
          </label>
          <input
            type="number"
            id="numberOfResources"
            name="numberOfResources"
            min="1"
            value={formData.numberOfResources}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded text-gray-800"
          />
        </div>
        <textarea
          name="description"
          placeholder="Describe your needs"
          value={formData.description}
          onChange={handleChange}
          required
          className="w-full mb-4 p-2 border border-gray-300 rounded text-gray-800"
          rows="4"
        ></textarea>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
        >
          Submit Request
        </button>
      </form>
    </div>
  );
}

export default ResourceRequestForm;



// import React, { useState, useEffect } from "react";
// import { useSearchParams, useNavigate } from "react-router-dom";
// import { doc, getDoc } from "firebase/firestore";
// import { db } from "../config/firebase";

// function ResourceRequestForm() {
//   const [technician, setTechnician] = useState(null);
//   const [searchParams] = useSearchParams();
//   const navigate = useNavigate();
//   const technicianId = searchParams.get("technicianId");

//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     urgency: "",
//     description: "",
//     numberOfResources: 1,
//   });

//   useEffect(() => {
//     const fetchTechnician = async () => {
//       if (technicianId) {
//         const docRef = doc(db, "technicians", technicianId);
//         const docSnap = await getDoc(docRef);
//         if (docSnap.exists()) {
//           setTechnician(docSnap.data());
//         } else {
//           console.error("No such document!");
//         }
//       }
//     };

//     fetchTechnician();
//   }, [technicianId]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: name === "numberOfResources" ? parseInt(value, 10) : value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     console.log("Form submitted:", { ...formData, technicianId });
    
//     // Reset form
//     setFormData({
//       name: "",
//       email: "",
//       urgency: "",
//       description: "",
//       numberOfResources: 1,
//     });

//     // Redirect to Calendly
//     window.location.href = "https://calendly.com/cam-sidekicksolutions/30min?month=2024-12";
//   };

//   return (
//     <div className="min-h-screen w-full bg-white px-4 py-8">
//       <button
//         onClick={() => navigate('/')}
//         className="mb-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600 shadow"
//       >
//         Back to Home
//       </button>
//       <h1 className="text-3xl font-bold mb-4 text-center text-blue-600">
//         Request a Resource
//       </h1>
//       {technician && (
//         <div className="mb-4 p-4 bg-white rounded-lg shadow">
//           <h2 className="text-xl font-semibold mb-2 text-gray-800">Selected Technician</h2>
//           <p className="text-gray-600">Name: {technician.firstName} {technician.lastName}</p>
//           <p className="text-gray-600">Location: {technician.city}, {technician.state}</p>
//           <p className="text-gray-600">Type: {technician.userType}</p>
//         </div>
//       )}
//       <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white p-6 rounded-lg shadow">
//         <input
//           type="text"
//           name="name"
//           placeholder="Your Name"
//           value={formData.name}
//           onChange={handleChange}
//           required
//           className="w-full mb-4 p-2 border border-gray-300 rounded text-gray-800"
//         />
//         <input
//           type="email"
//           name="email"
//           placeholder="Your Email"
//           value={formData.email}
//           onChange={handleChange}
//           required
//           className="w-full mb-4 p-2 border border-gray-300 rounded text-gray-800"
//         />
//         <input
//           type="text"
//           name="urgency"
//           placeholder="How quickly do you need these resources?"
//           value={formData.urgency}
//           onChange={handleChange}
//           required
//           className="w-full mb-4 p-2 border border-gray-300 rounded text-gray-800"
//         />
//         <div className="mb-4">
//           <label htmlFor="numberOfResources" className="block text-sm font-medium text-gray-700 mb-1">
//             Number of Resources
//           </label>
//           <input
//             type="number"
//             id="numberOfResources"
//             name="numberOfResources"
//             min="1"
//             value={formData.numberOfResources}
//             onChange={handleChange}
//             required
//             className="w-full p-2 border border-gray-300 rounded text-gray-800"
//           />
//         </div>
//         <textarea
//           name="description"
//           placeholder="Describe your needs"
//           value={formData.description}
//           onChange={handleChange}
//           required
//           className="w-full mb-4 p-2 border border-gray-300 rounded text-gray-800"
//           rows="4"
//         ></textarea>
//         <button
//           type="submit"
//           className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
//         >
//           Submit Request
//         </button>
//       </form>
//     </div>
//   );
// }

// export default ResourceRequestForm;


