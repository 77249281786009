import React from "react";

const sponsors = [
  {
    name: "Lee Health",
    image: "/assets/images/Lee-Health-Master-logo-clr_web.png",
    url: "https://www.leehealth.org",
  },
  {
    name: "MaineHealth",
    image: "/assets/images/MaineHealth-RGB-Preferred-Logo-Digital.svg",
    url: "https://www.mainehealth.org/",
  },
  {
    name: "St. Luke's",
    image: "/assets/images/St Lukes Logo dark blue.svg",
    url: "https://www.slhn.org/",
  },
  {
    name: "UR Medicine",
    image: "/assets/images/UR-Medicine-logo-svg.svg",
    url: "https://www.urmc.rochester.edu/",
  },
  {
    name: "Renovo Solutions",
    image: "/assets/images/res-logo-MC0.webp",
    url: "https://renovo1.com/",
  },
  {
    name: "Specialty Care",
    image: "/assets/images/SpecialtyCareLogo_Navy-1-2048x489.webp",
    url: "https://specialtycareus.com",
  },
];

function Sponsors() {
  return (
    <div className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-blue-600 mb-8">
          Our Clients
        </h2>
        <p className="text-xl text-center text-gray-600 mb-12">
          Our clients' exceptional support has made this map possible.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {sponsors.map((sponsor) => (
            <div
              key={sponsor.name}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-200"
            >
              <a
                href={sponsor.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <img
                  src={sponsor.image}
                  alt={sponsor.name}
                  className="mx-auto h-24 object-contain"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sponsors;



// import React from "react";

// const sponsors = [
//   {
//     name: "Lee Health",
//     image: "/assets/images/Lee-Health-Master-logo-clr_web.png",
//     url: "https://www.leehealth.org",
//   },
//   {
//     name: "MaineHealth",
//     image: "/assets/images/MaineHealth-RGB-Preferred-Logo-Digital.svg",
//     url: "https://www.mainehealth.org/",
//   },
//   {
//     name: "St. Luke's",
//     image: "/assets/images/St Lukes Logo dark blue.svg",
//     url: "https://www.slhn.org/",
//   },
//   {
//     name: "UR Medicine",
//     image: "/assets/images/UR-Medicine-logo-svg.svg",
//     url: "https://www.urmc.rochester.edu/",
//   },
//   {
//     name: "Renovo Solutions",
//     image: "/assets/images/res-logo-MC0.webp",
//     url: "https://renovo1.com/",
//   },
//   {
//     name: "Specialty Care",
//     image: "/assets/images/SpecialtyCareLogo_Navy-1-2048x489.webp",
//     url: "https://specialtycareus.com",
//   },
// ];

// function Sponsors() {
//   return (
//     <div className="bg-surface py-16">
//       <div className="container mx-auto px-4">
//         <h2 className="text-3xl font-bold text-center text-techmap mb-8">
//           Our Clients
//         </h2>
//         <p className="text-xl text-center text-text-secondary mb-12">
//           Our clients' exceptional support has made this map possible.
//         </p>
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
//           {sponsors.map((sponsor) => (
//             <div
//               key={sponsor.name}
//               className="bg-background p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-700"
//             >
//               <a
//                 href={sponsor.url}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="block"
//               >
//                 <img
//                   src={sponsor.image}
//                   alt={sponsor.name}
//                   className="mx-auto h-24 object-contain filter invert"
//                 />
//               </a>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Sponsors;
