import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header className="bg-white shadow-md">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img
            src="/assets/images/sidekick-textured-logo.png"
            alt="SideKick Logo"
            className="h-10 w-auto"
          />
        </Link>
        <nav>
          <ul className="flex space-x-4 text-sm font-medium">
            <li>
              <Link
                to="/signin"
                className="text-gray-700 hover:text-blue-600 transition-colors duration-200"
              >
                Sign In
              </Link>
            </li>
            <li>
              <Link
                to="/signup"
                className="text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200 px-4 py-2 rounded-md"
              >
                Join the Techmap
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;

