import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import { ArrowLeft } from 'lucide-react';
import { Loader } from "@googlemaps/js-api-loader";
import { Link } from "react-router-dom";

const deviceTypes = [
  "Badge Reader",
  "Badge Reader - Imprivata",
  "BCA/EpicDownTown (Business Continuity Application)",
  "BCA (Printers)",
  "BCA (UPS)",
  "BCA PC",
  "BCMA Scanner - Wireless",
  "Bluetooth Receivers - EPCS",
  "Desktops/PC (Micro Form Factors)",
  "Desktop/PC - Acer",
  "Desktop/PC - All-In-Ones (Ex: Dell, Lenovo, etc.)",
  "Desktop/PC - Apple",
  "Desktop/PC - Asus",
  "Desktop/PC - Dell",
  "Desktop/PC - HP",
  "Desktop/PC - Lenovo",
  "Desktop/PC - Samsung",
  "Display Boards",
  "Instamed Credit Card Swipe",
  "Laptops - Macintosh",
  "Laptops - Standard",
  "Mobile Phones - Android",
  "Mobile Phones - iOS",
  "Mobile Phones - Rover",
  "Monitors",
  "Printer - 3D (Ex: Formlabs Form)",
  "Printer - All-In-One (Ex: Brother MFC Series)",
  "Printer - Barcode (Ex: Honeywell)",
  "Printer - Desktop (Ex: Canon Pixma)",
  "Printer - ID Card (Ex: Zebra ZC Series)",
  "Printer - Inkjet (Ex: HP DeskJet)",
  "Printer - Laser (Ex: HP Laser)",
  "Printer - Label (Ex: Zebra ZD series)",
  "Printer - Multifunction (MFP's) (Ex: Xerox AltaLink)",
  "Printer - Network",
  "Printer - Portable (Ex: Canon PIXMA)",
  "Printer - Receipt (Ex: Epson TM-T88 Series)",
  "Printer - Thermal (Ex: Epson TM Series)",
  "Printer - UV",
  "Printer - Wide Format (Ex: Epson SureColor)",
  "Printer - Wireless (Ex: HP Smart Tank)",
  "Printer - Wristband (Ex: Brother TD Series)",
  "Registration Camera",
  "Scanner - Barcode (Ex: Zebra DS series)",
  "Scanner - Document (Ex: Fujitsu fi Series)",
  "Scanner - Drum (Ex: ICG)",
  "Scanner - Flatbed (Ex: Canon CanoScan)",
  "Scanner - Handheld (Ex: IRIScan)",
  "Scanner - Photo (Ex: Epson FastFoto Series)",
  "Scanner - Portable (Ex: Brother PocketJet series)",
  "Scanner - Sheetfed (Ex: Brother ADS Series)",
  "Signature Pads - Basic",
  "Signature Pads - Biometric",
  "Signature Pads - IP",
  "Signature Pads - LCD",
  "Signature Pads - Multi-Function",
  "Signature Pads - Portable/Wireless",
  "Signature Pads - Touchscreen",
  "Signature Pads - USB",
  "Tablet - Android",
  "Tablet - iPad",
  "Tablet - Surface Pro",
  "Wall Brackets (Ex: Zebra Printer)",
  "Wall Mounted Arms",
  "WOW Carts",
  "WOW Cart - All-In-One (AIO's)",
  "Topaz Signature Pad",
  "Armband Printer - Zebra ZD - 510",
  "WOW - ED Nurse Charting",
  "WOW - Registration",
  "EPCS - Bluetooth Receiver",
  "Plain Paper Printer",
  "Label Printers",
  "Document Scanner",
  "Other",
];

const deploymentProjects = [
  "Win10 to Win11",
  "Epic Readiness & Refresh",
  "TDR",
  "Epic Cutover / Go-live Support",
  "IT Construction / New Hospital Build-out",
  "Workstation Upgrades",
  "Other",
];

export default function DeploymentTechQuestionnaire() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [formData, setFormData] = useState({
    hasDeployedHardware: false,
    yearsOfExperience: "",
    deviceTypesDeployed: [],
    deploymentProjects: [],
    otherDeployedProjects: "",
    hasReliableTransportation: false,
    willingToTravelOrRelocate: false,
    city: "",
    state: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox"
          ? checked
            ? [...prevState[name], value]
            : prevState[name].filter((item) => item !== value)
          : value,
    }));
  };

  const geocodeAddress = async (city, state) => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: "weekly",
    });

    const google = await loader.load();
    const geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: `${city}, ${state}` }, (results, status) => {
        if (status === "OK") {
          const { lat, lng } = results[0].geometry.location;
          resolve({ lat: lat(), lng: lng() });
        } else {
          reject(new Error("Geocoding failed"));
        }
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { city, state } = formData;
      const geolocation = await geocodeAddress(city, state);
      await setDoc(doc(db, "technicians", userId), { ...formData, location: geolocation }, { merge: true });
      navigate("/technician-dashboard");
    } catch (error) {
      console.error("Error updating technician data:", error);
    }
  };

  return (
    <div className="min-h-screen bg-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full space-y-8">
        <div>
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </button>
          <Link to="/" className="flex items-center justify-center">
            <img
              src="/assets/images/sidekick-textured-logo.png"
              alt="SideKick Logo"
              className="h-16"
            />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Deployment Tech Questionnaire
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Thank you for filling out this quick survey! We will add you as an active user to the Sidekick TechMap so that hospitals can hire you for their upcoming IT projects.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 space-y-8 bg-gray-50 p-8 rounded-lg shadow-sm">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Have you deployed hardware equipment into a healthcare environment?
              </label>
              <div className="mt-2 space-x-6">
                {["Yes", "No"].map((option) => (
                  <label key={option} className="inline-flex items-center">
                    <input
                      type="radio"
                      name="hasDeployedHardware"
                      value={option === "Yes"}
                      checked={formData.hasDeployedHardware === (option === "Yes")}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          hasDeployedHardware: e.target.value === "true",
                        }))
                      }
                      className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-700">{option}</span>
                  </label>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                How many years of experience do you have as a deployment technician?
              </label>
              <div className="mt-2 grid grid-cols-2 gap-2">
                {["0-1", "2-4", "4-8", "8+"].map((years) => (
                  <label key={years} className="inline-flex items-center">
                    <input
                      type="radio"
                      name="yearsOfDeploymentExperience"
                      value={years}
                      checked={formData.yearsOfDeploymentExperience === years}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          yearsOfDeploymentExperience: e.target.value,
                        }))
                      }
                      className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      {years} years
                    </span>
                  </label>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                How many years have you deployed hardware equipment into a healthcare environment?
              </label>
              <div className="mt-2 grid grid-cols-2 gap-2">
                {["0-1", "2-4", "4-8", "8+"].map((years) => (
                  <label key={years} className="inline-flex items-center">
                    <input
                      type="radio"
                      name="yearsOfHealthcareDeployment"
                      value={years}
                      checked={formData.yearsOfHealthcareDeployment === years}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          yearsOfHealthcareDeployment: e.target.value,
                        }))
                      }
                      className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      {years} years
                    </span>
                  </label>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-6 bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Which of these device types have you deployed into a healthcare environment?
            </h3>
            <div className="grid grid-cols-2 gap-4">
              {deviceTypes.map((device) => (
                <div key={device} className="flex items-start">
                  <input
                    type="checkbox"
                    id={device}
                    name="deviceTypesDeployed"
                    value={device}
                    checked={formData.deviceTypesDeployed.includes(device)}
                    onChange={handleChange}
                    className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 mt-1"
                  />
                  <label htmlFor={device} className="ml-3 text-sm text-gray-700">
                    {device}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6 bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Mark all deployment projects you've done in a healthcare setting in the past
            </h3>
            <div className="grid grid-cols-2 gap-4">
              {deploymentProjects.map((project) => (
                <div key={project} className="flex items-start">
                  <input
                    type="checkbox"
                    id={project}
                    name="deploymentProjects"
                    value={project}
                    checked={formData.deploymentProjects.includes(project)}
                    onChange={handleChange}
                    className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 mt-1"
                  />
                  <label htmlFor={project} className="ml-3 text-sm text-gray-700">
                    {project}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6">
            <label htmlFor="otherProjects" className="block text-sm font-medium text-gray-700">
              Other deployment projects / devices
            </label>
            <div className="mt-1">
              <textarea
                id="otherProjects"
                name="otherDeployedProjects"
                rows="3"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Please describe any other deployment projects..."
                value={formData.otherDeployedProjects}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>

          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700">
              Do you have reliable transportation?
            </label>
            <div className="mt-2 space-x-6">
              {["Yes", "No"].map((option) => (
                <label key={option} className="inline-flex items-center">
                  <input
                    type="radio"
                    name="hasReliableTransportation"
                    value={option === "Yes"}
                    checked={formData.hasReliableTransportation === (option === "Yes")}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        hasReliableTransportation: e.target.value === "true",
                      }))
                    }
                    className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">{option}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700">
              Are you willing to travel? (Travel in for a job?)
            </label>
            <div className="mt-2 space-x-6">
              {["Yes", "No"].map((option) => (
                <label key={option} className="inline-flex items-center">
                  <input
                    type="radio"
                    name="willingToTravel"
                    value={option === "Yes"}
                    checked={formData.willingToTravel === (option === "Yes")}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        willingToTravel: e.target.value === "true",
                      }))
                    }
                    className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">{option}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700">
              Are you willing to relocate? (Moving to a new city?)
            </label>
            <div className="mt-2 space-x-6">
              {["Yes", "No"].map((option) => (
                <label key={option} className="inline-flex items-center">
                  <input
                    type="radio"
                    name="willingToRelocate"
                    value={option === "Yes"}
                    checked={formData.willingToRelocate === (option === "Yes")}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        willingToRelocate: e.target.value === "true",
                      }))
                    }
                    className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">{option}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700">
              Where are you located?
            </label>
            <div className="mt-2 grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-black"
                  placeholder="Enter city"
                />
              </div>
              <div>
                <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-black"
                  placeholder="Enter state"
                />
              </div>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}


