const debugEnv = {
  checkEnv: () => {
    const envVars = {
      FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
      FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    };

    console.table(envVars);

    // Check if values are actually strings and not undefined
    Object.entries(envVars).forEach(([key, value]) => {
      console.log(`${key} type:`, typeof value);
      console.log(`${key} length:`, value?.length);
    });

    return envVars;
  },
};

export default debugEnv;
