import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import { ArrowLeft } from 'lucide-react';
import { Link } from "react-router-dom";
import { Loader } from "@googlemaps/js-api-loader";

export default function BMETQuestionnaire() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [formData, setFormData] = useState({
    yearsOfExperience: "",
    bmetLevel: "",
    equipmentExperience: {
      general: [],
      or: [],
      radiology: [],
      dialysis: []
    },
    otherEquipment: "",
    hasReliableTransportation: null,
    willingToTravel: null,
    city: "",
    state: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === "checkbox") {
      const [category, ...equipmentParts] = name.split(".");
      const equipment = equipmentParts.join("."); // Rejoin in case equipment name contains dots
      
      setFormData(prevState => ({
        ...prevState,
        equipmentExperience: {
          ...prevState.equipmentExperience,
          [category]: checked
            ? [...prevState.equipmentExperience[category], equipment]
            : prevState.equipmentExperience[category].filter(item => item !== equipment)
        }
      }));
    } else if (name === "hasReliableTransportation" || name === "willingToTravel") {
      setFormData(prevState => ({
        ...prevState,
        [name]: value === "true"
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const geocodeAddress = async (city, state) => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: "weekly",
    });
    
    const google = await loader.load();
    const geocoder = new google.maps.Geocoder();
    
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: `${city}, ${state}` }, (results, status) => {
        if (status === "OK") {
          const { lat, lng } = results[0].geometry.location;
          resolve({ lat: lat(), lng: lng() });
        } else {
          reject(new Error("Geocoding failed"));
        }
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { city, state } = formData;
      const geolocation = await geocodeAddress(city, state);
      await setDoc(doc(db, "technicians", userId), { ...formData, location: geolocation }, { merge: true });
      navigate("/technician-dashboard");
    } catch (error) {
      console.error("Error updating technician data:", error);
    }
  };

  const equipmentLists = {
    general: [
      "Air/Oxygen Blenders - Bio-Med Devices",
      "Air/Oxygen Blenders - Precision Medical",
      "Air/Oxygen Blenders - Ohio Medical",
      "Air/Oxygen Blenders - Other",
      "Apheresis Devices - Terumo",
      "Apheresis Devices - Fresenius Kabi",
      "Apheresis Devices - Haemonetics",
      "Apheresis Devices - Macopharma",
      "Apheresis Devices - Other",
      "Beds (ICU/MedSurg/Birthing) - Hill-Rom",
      "Beds (ICU/MedSurg/Birthing) - Stryker",
      "Beds (ICU/MedSurg/Birthing) - ArjoHuntleigh",
      "Beds (ICU/MedSurg/Birthing) - Invacare",
      "Beds (ICU/MedSurg/Birthing) - Other",
      "Bladder Scanners - Verathon",
      "Bladder Scanners - Laborie",
      "Bladder Scanners - MCube Technology",
      "Bladder Scanners - Vitacon",
      "Bladder Scanners - Other",
      "Continuous Cardiac Output Monitors - Edwards Lifesciences",
      "Continuous Cardiac Output Monitors - Baxter",
      "Continuous Cardiac Output Monitors - GE Healthcare",
      "Continuous Cardiac Output Monitors - Other",
      "Defibrillators/AED Defibs - Zoll",
      "Defibrillators/AED Defibs - Physio-Control",
      "Defibrillators/AED Defibs - Philips Healthcare",
      "Defibrillators/AED Defibs - Cardiac Science",
      "Defibrillators/AED Defibs - Other",
      "Dopplers - Fetal/Vascular - Huntleigh",
      "Dopplers - Fetal/Vascular - Newman Medical",
      "Dopplers - Fetal/Vascular - Edan Instruments",
      "Dopplers - Fetal/Vascular - Summit Doppler",
      "Dopplers - Fetal/Vascular - Other",
      "EKG Machines - GE Healthcare",
      "EKG Machines - Philips Healthcare",
      "EKG Machines - Welch Allyn",
      "EKG Machines - Schiller",
      "EKG Machines - Other",
      "Feeding Pump - Kangaroo - Cardinal Health",
      "Feeding Pump - Kangaroo - Medela",
      "Feeding Pump - Kangaroo - Abbott",
      "Feeding Pump - Kangaroo - Moog",
      "Feeding Pump - Kangaroo - Other",
      "Fetal Monitors - GE Healthcare",
      "Fetal Monitors - Philips Healthcare",
      "Fetal Monitors - Edan Instruments",
      "Fetal Monitors - Huntleigh",
      "Fetal Monitors - Other",
      "Fluid Warmer - Blood/IV Solution Warmer - Smiths Medical",
      "Fluid Warmer - Blood/IV Solution Warmer - Belmont Instrument",
      "Fluid Warmer - Blood/IV Solution Warmer - Enthermics Medical Systems",
      "Fluid Warmer - Blood/IV Solution Warmer - 3M",
      "Fluid Warmer - Blood/IV Solution Warmer - Other",
      "Gym Equipment",
      "Hypo/Hyperthermia Machines - Medtronic",
      "Hypo/Hyperthermia Machines - Cincinnati Sub-Zero",
      "Hypo/Hyperthermia Machines - Stryker",
      "Hypo/Hyperthermia Machines - 3M",
      "Hypo/Hyperthermia Machines - Other",
      "Infant Incubators - GE Healthcare",
      "Infant Incubators - Dräger",
      "Infant Ventilator - Dräger",
      "Infant Ventilator - GE Healthcare",
      "Infant Ventilator - CareFusion",
      "Infant Ventilator - Philips Healthcare",
      "Infant Ventilator - Other",
      "Infant Warmer - GE Healthcare",
      "Infant Warmer - Dräger",
      "Infant Warmer - Other",
      "Monitors, Hardwired Bedside Physiological - Philips Healthcare",
      "Monitors, Hardwired Bedside Physiological - GE Healthcare",
      "Monitors, Transport Physiological, Critical Care - Zoll",
      "Monitors, Transport Physiological, Critical Care - Philips Healthcare",
      "Monitors, Transport Physiological, Med/Surg - Welch Allyn",
      "Monitors, Transport Physiological, Med/Surg - Mindray",
      "Monitors, Transport Physiological, Med/Surg - Other",
      "Nurse Call Systems - Rauland-Borg",
      "Nurse Call Systems - Ascom",
      "Nurse Call Systems - Hill-Rom",
      "Nurse Call Systems - Other",
      "Ophthalmoscope/Otoscope - Welch Allyn",
      "Ophthalmoscope/Otoscope - Heine Optotechnik",
      "Ophthalmoscope/Otoscope - Other",
      "Patient Lifts - Hill-Rom",
      "Patient Lifts - ArjoHuntleigh",
      "Patient Lifts - Other",
      "PCA/Epidural Pumps - Smiths Medical",
      "PCA/Epidural Pumps - B. Braun",
      "PCA/Epidural Pumps - Other",
      "Phototherapy Lights - GE Healthcare",
      "Phototherapy Lights - Philips Healthcare",
      "Phototherapy Lights - Natus Medical",
      "Phototherapy Lights - Other",
      "Pulse Oximeter - Bedside/Handheld - Masimo",
      "Pulse Oximeter - Bedside/Handheld - Nellcor",
      "Pulse Oximeter - Bedside/Handheld - Philips Healthcare",
      "Pulse Oximeter - Bedside/Handheld - Other",
      "Sequential Compression Device - Cardinal Health",
      "Sequential Compression Device - Zimmer Biomet",
      "Sequential Compression Device - Other",
      "Sphygmometer, Electronic (BP Cuff/Machine) - Welch Allyn",
      "Sphygmometer, Electronic (BP Cuff/Machine) - Omron",
      "Sphygmometer, Electronic (BP Cuff/Machine) - GE Healthcare",
      "Sphygmometer, Electronic (BP Cuff/Machine) - Other",
      "Telemetry Transmitters - Philips Healthcare",
      "Telemetry Transmitters - GE Healthcare",
      "Telemetry Transmitters - Spacelabs Healthcare",
      "Telemetry Transmitters - Other",
      "Thermometers, Electronic, Oral/Temporal - Welch Allyn",
      "Thermometers, Electronic, Oral/Temporal - Exergen",
      "Thermometers, Electronic, Oral/Temporal - Omron",
      "Thermometers, Electronic, Oral/Temporal - Covidien",
      "Thermometers, Electronic, Oral/Temporal - Vicks",
      "Thermometers, Electronic, Oral/Temporal - Other",
      "Ventilator - Dräger",
      "Ventilator - GE Healthcare",
      "Ventilator - Philips Healthcare",
      "Ventilator - Other",
      "Warming Blanket / Bear Hugger - 3M",
      "Warming Blanket / Bear Hugger - Enthermics Medical Systems",
      "Warming Blanket / Bear Hugger - Stryker",
      "Warming Blanket / Bear Hugger - Other"
    ],
    or: [
      "Ablation - Medtronic",
      "Ablation - Boston Scientific",
      "Ablation - St Jude",
      "Ablation - Biosense Webster",
      "Ablation - Other",
      "Argon Beam Coagulators - CONMED",
      "Argon Beam Coagulators - ERBE",
      "Argon Beam Coagulators - Bovie",
      "Argon Beam Coagulators - Megadyne",
      "Argon Beam Coagulators - Other",
      "Balloon Pumps - Maquet",
      "Balloon Pumps - Arrow International",
      "Balloon Pumps - Datascope",
      "Balloon Pumps - Teleflex",
      "Balloon Pumps - Other",
      "Electrosurgical Unit - Medtronic",
      "Electrosurgical Unit - Bovie",
      "Electrosurgical Unit - ConMed",
      "Electrosurgical Unit - ERBE",
      "Electrosurgical Unit - Other",
      "Pacemaker, External A/V Sequential - Medtronic",
      "Pacemaker, External A/V Sequential - Zoll",
      "Pacemaker, External A/V Sequential - St. Jude Medical",
      "Pacemaker, External A/V Sequential - Other",
      "Pumps, IV - Baxter",
      "Pumps, IV - B. Braun",
      "Pumps, IV - Smiths Medical",
      "Pumps, IV - Other",
      "Suction, Portable - Laerdal",
      "Suction, Portable - DeVilbiss Healthcare",
      "Suction, Portable - Impact Instrumentation",
      "Suction, Portable - Other",
      "Syringe Pumps - Smiths Medical",
      "Syringe Pumps - B. Braun",
      "Syringe Pumps - BD",
      "Syringe Pumps - Other",
      "Video Towers/Printers/Light Sources - Stryker",
      "Video Towers/Printers/Light Sources - Olympus",
      "Video Towers/Printers/Light Sources - Karl Storz",
      "Video Towers/Printers/Light Sources - Other"
    ],
    radiology: [
      "Cath Lab/IR - Siemens Healthineers Artis zee",
      "Cath Lab/IR - Philips Healthcare Azurion 7",
      "Cath Lab/IR - GE Healthcare AlluraClarity FD20",
      "Cath Lab/IR - Other",
      "CT - GE Healthcare Optima CT660",
      "CT - GE Healthcare Revolution EVO",
      "CT - Siemens Healthineers SOMATOM Definition AS",
      "CT - Siemens Healthineers SOMATOM Force",
      "CT - Toshiba Aquilion Prime SP",
      "CT - Hitachi SCENARIA View",
      "CT - Carestream OnSight 3D Extremity System",
      "CT - Canon Aquilion ONE",
      "CT - Siemens Healthineers SOMATOM Sensation",
      "CT - Other",
      "Mammography - Hologic Selenia Dimensions",
      "Mammography - Siemens Healthineers MAMMOMAT Revelation",
      "Mammography - GE Healthcare Senographe Pristina",
      "Mammography - Fujifilm Amulet",
      "Mammography - Other",
      "MRI - GE Healthcare Discovery MR750w",
      "MRI - Siemens Healthineers MAGNETOM Vida",
      "MRI - Siemens Healthineers MAGNETOM Skyra",
      "MRI - Philips Healthcare Ingenia Ambition 1.5T",
      "MRI - Toshiba Vantage Orian 1.5T",
      "MRI - Philips Healthcare Achieva 1.5T",
      "MRI - Hitachi Oasis 1.2T",
      "MRI - Canon Vantage Galan 3T",
      "MRI - Other",
      "PET/CT - Siemens Healthineers Biograph mCT",
      "PET/CT - GE Healthcare Discovery MI",
      "PET/CT - Philips Healthcare Vereos Digital",
      "PET/CT - Toshiba Celesteion",
      "PET/CT - Other",
      "Ultrasound - GE Healthcare Logiq E9",
      "Ultrasound - GE Healthcare Vivid E95 Echocardiography",
      "Ultrasound - Philips Healthcare EPIQ Elite",
      "Ultrasound - Philips Healthcare Affiniti 70",
      "Ultrasound - Philips Healthcare EPIQ 7",
      "Ultrasound - Philips Healthcare IU22",
      "Ultrasound - Toshiba Aplio 500",
      "Ultrasound - Hitachi Arietta 850",
      "Ultrasound - Canon Aplio i-series",
      "Ultrasound - Siemens Healthineers ACUSON S2000",
      "Ultrasound - Fujifilm Arietta 60",
      "Ultrasound - Other",
      "X-ray - GE Healthcare OEC 9900 Elite",
      "X-ray - Philips Healthcare DigitalDiagnost C90",
      "X-ray - Hologic Fluoroscan InSight FD Mini C-Arm",
      "X-ray - Carestream DRX-Revolution",
      "X-ray - Siemens Healthineers Multix Fusion",
      "X-ray - Canon RadPRO 40kW",
      "X-ray - Agfa Healthcare DX-D 300",
      "X-ray - Fujifilm FDR Go PLUS",
      "X-ray - Other"
    ],
    dialysis: [
      "CVVH Hemodialysis Machines - Fresenius 2008T Hemodialysis Machine",
      "CVVH Hemodialysis Machines - Baxter Prismaflex",
      "CVVH Hemodialysis Machines - Baxter PrisMax",
      "CVVH Hemodialysis Machines - B. Braun Diapact CRRT",
      "CVVH Hemodialysis Machines - B. Braun Dialog+",
      "CVVH Hemodialysis Machines - NxStage System One",
      "CVVH Hemodialysis Machines - Other",
      "Dialysis Machine - Fresenius 2008T",
      "Dialysis Machine - Fresenius 2008K",
      "Dialysis Machine - B. Braun Dialog+",
      "Dialysis Machine - B. Braun Dialog iQ",
      "Dialysis Machine - NxStage PureFlow SL",
      "Dialysis Machine - Nipro SURDIAL DX",
      "Dialysis Machine - Nipro Surdial X",
      "Dialysis Machine - Other"
    ]
  };

  return (
    <div className="min-h-screen bg-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full space-y-8">
        <div>
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </button>
          <Link to="/" className="flex items-center justify-center">
            <img
              src="/assets/images/sidekick-textured-logo.png"
              alt="SideKick Logo"
              className="h-16"
            />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            BMET Questionnaire
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Thank you for filling out this quick survey! We will add you as an active user to the Sidekick TechMap so that hospitals can hire you for their upcoming biomed projects.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 space-y-8 bg-gray-50 p-8 rounded-lg shadow-sm">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-3">
                How many years of experience do you have as a BMET?
              </label>
              <div className="space-x-6">
                {["0-2", "2-6", "7+"].map((years) => (
                  <label key={years} className="inline-flex items-center cursor-pointer">
                    <input
                      type="radio"
                      name="yearsOfExperience"
                      value={years}
                      checked={formData.yearsOfExperience === years}
                      onChange={handleChange}
                      className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer"
                    />
                    <span className="ml-2 text-gray-900">{years}</span>
                  </label>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-3">
                BMET Level
              </label>
              <div className="space-x-6">
                {["BMET I", "BMET II", "BMET III", "Imaging Engineer"].map((level) => (
                  <label key={level} className="inline-flex items-center cursor-pointer">
                    <input
                      type="radio"
                      name="bmetLevel"
                      value={level}
                      checked={formData.bmetLevel === level}
                      onChange={handleChange}
                      className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer"
                    />
                    <span className="ml-2 text-gray-900">{level}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          {Object.entries(equipmentLists).map(([category, items]) => (
            <div key={category} className="mt-6 bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                {category.charAt(0).toUpperCase() + category.slice(1)} equipment you've worked with:
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {items.map((item) => (
                  <div key={item} className="flex items-start">
                    <label className="inline-flex items-start cursor-pointer">
                      <input
                        type="checkbox"
                        name={`${category}.${item}`}
                        checked={formData.equipmentExperience[category].includes(item)}
                        onChange={handleChange}
                        className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 cursor-pointer mt-1"
                      />
                      <span className="ml-3 text-gray-900">{item}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="mt-6">
            <label htmlFor="otherEquipment" className="block text-sm font-medium text-gray-700">
              Other Equipment
            </label>
            <div className="mt-1">
              <textarea
                id="otherEquipment"
                name="otherEquipment"
                rows="3"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md text-gray-900"
                placeholder="Please list any other equipment you have experience with"
                value={formData.otherEquipment}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700 mb-3">
              Do you have reliable transportation?
            </label>
            <div className="space-x-6">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="hasReliableTransportation"
                  value="true"
                  checked={formData.hasReliableTransportation === true}
                  onChange={handleChange}
                  className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer"
                />
                <span className="ml-2 text-gray-900">Yes</span>
              </label>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="hasReliableTransportation"
                  value="false"
                  checked={formData.hasReliableTransportation === false}
                  onChange={handleChange}
                  className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer"
                />
                <span className="ml-2 text-gray-900">No</span>
              </label>
            </div>
          </div>
          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700 mb-3">
              Are you willing to travel? (Travel for a job)
            </label>
            <div className="space-x-6">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="willingToTravel"
                  value="true"
                  checked={formData.willingToTravel === true}
                  onChange={handleChange}
                  className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer"
                />
                <span className="ml-2 text-gray-900">Yes</span>
              </label>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="willingToTravel"
                  value="false"
                  checked={formData.willingToTravel === false}
                  onChange={handleChange}
                  className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer"
                />
                <span className="ml-2 text-gray-900">No</span>
              </label>
            </div>
          </div>
          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700 mb-3">
              Where are you located?
            </label>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-base"
                  placeholder="Enter city"
                />
              </div>
              <div>
                <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-base"
                  placeholder="Enter state"
                />
              </div>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
