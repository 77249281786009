import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../config/firebase";
import { ArrowLeft, Eye, EyeOff } from 'lucide-react';

function SignUp() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    userType: "",
    areaCode: "",
    centralCode: "",
    stationCode: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      await setDoc(doc(db, "technicians", user.uid), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        userType: formData.userType,
        phoneNumber: `+1${formData.areaCode}${formData.centralCode}${formData.stationCode}`,
      });

      if (formData.userType === "BMET") {
        navigate(`/bmet-questionnaire/${user.uid}`);
      } else if (formData.userType === "Deployment Technician") {
        navigate(`/deployment-questionnaire/${user.uid}`);
      }
    } catch (error) {
      console.error("Error signing up:", error);
      alert("Failed to sign up. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-md">
        <div className="space-y-6">
          <button
            onClick={() => navigate("/")}
            className="flex items-center text-blue-600 hover:text-blue-800 transition-colors"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Home
          </button>
          <div className="text-center space-y-2">
            <h2 className="text-3xl font-bold text-gray-900">
              Join the Techmap
            </h2>
            <p className="text-gray-600">
              Connect with technical resources worldwide
            </p>
          </div>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSignUp}>
          <div className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  First Name
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  required
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                  placeholder="John"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Last Name
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  required
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                  placeholder="Doe"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="phone-number"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Phone Number
              </label>
              <div className="flex space-x-2 items-center">
                <span className="text-gray-700 bg-gray-200 px-2 py-2 rounded-md">+1</span>
                <input
                  id="area-code"
                  name="areaCode"
                  type="text"
                  maxLength="3"
                  required
                  className="appearance-none w-14 px-2 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-center"
                  placeholder="123"
                  value={formData.areaCode}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length === 3) document.getElementById("central-code").focus();
                    setFormData((prev) => ({ ...prev, areaCode: value }));
                  }}
                />
                <input
                  id="central-code"
                  name="centralCode"
                  type="text"
                  maxLength="3"
                  required
                  className="appearance-none w-14 px-2 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-center"
                  placeholder="456"
                  value={formData.centralCode}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length === 3) document.getElementById("station-code").focus();
                    setFormData((prev) => ({ ...prev, centralCode: value }));
                  }}
                />
                <input
                  id="station-code"
                  name="stationCode"
                  type="text"
                  maxLength="4"
                  required
                  className="appearance-none w-16 px-2 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-center"
                  placeholder="7890"
                  value={formData.stationCode}
                  onChange={(e) => {
                    setFormData((prev) => ({ ...prev, stationCode: e.target.value }));
                  }}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email-address"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Email Address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                placeholder="john@example.com"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  required
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                  placeholder="••••••••"
                  value={formData.password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 hover:text-gray-700 transition-colors"
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5" />
                  ) : (
                    <Eye className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>

            <div>
              <label
                htmlFor="userType"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Your Role
              </label>
              <select
                id="userType"
                name="userType"
                required
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                value={formData.userType}
                onChange={handleChange}
              >
                <option value="">Select your role</option>
                <option value="BMET">BMET</option>
                <option value="Deployment Technician">
                  Deployment Technician
                </option>
              </select>
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? "Creating account..." : "Create Account"}
            </button>
          </div>

          <div className="text-center text-sm">
            <span className="text-gray-600">Already have an account? </span>
            <button
              type="button"
              onClick={() => navigate("/signin")}
              className="font-medium text-blue-600 hover:text-blue-800 transition-colors"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUp;



// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { createUserWithEmailAndPassword } from "firebase/auth";
// import { doc, setDoc } from "firebase/firestore";
// import { auth, db } from "../config/firebase";
// import { ArrowLeft, Eye, EyeOff } from "lucide-react";

// function SignUp() {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//     firstName: "",
//     lastName: "",
//     userType: "",
//   });
//   const [showPassword, setShowPassword] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleSignUp = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     try {
//       const userCredential = await createUserWithEmailAndPassword(
//         auth,
//         formData.email,
//         formData.password
//       );
//       const user = userCredential.user;

//       await setDoc(doc(db, "technicians", user.uid), {
//         firstName: formData.firstName,
//         lastName: formData.lastName,
//         email: formData.email,
//         userType: formData.userType,
//       });

//       if (formData.userType === "BMET") {
//         navigate(`/bmet-questionnaire/${user.uid}`);
//       } else if (formData.userType === "Deployment Technician") {
//         navigate(`/deployment-questionnaire/${user.uid}`);
//       }
//     } catch (error) {
//       console.error("Error signing up:", error);
//       alert("Failed to sign up. Please try again.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="min-h-screen bg-[#1a1a1a] flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
//       <div className="max-w-md w-full space-y-8 bg-[#2a2a2a] p-8 rounded-xl shadow-lg">
//         <div className="space-y-6">
//           <button
//             onClick={() => navigate("/")}
//             className="flex items-center text-[#aad5e7] hover:text-[#6489a2] transition-colors"
//           >
//             <ArrowLeft className="w-4 h-4 mr-2" />
//             Back to Home
//           </button>
//           <div className="text-center space-y-2">
//             <h2 className="text-3xl font-bold text-[#aad5e7]">
//               Join the Techmap
//             </h2>
//             <p className="text-[#6489a2]">
//               Connect with technical resources worldwide
//             </p>
//           </div>
//         </div>

//         <form className="mt-8 space-y-6" onSubmit={handleSignUp}>
//           <div className="space-y-4">
//             <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
//               <div>
//                 <label
//                   htmlFor="firstName"
//                   className="block text-sm font-medium text-[#aad5e7] mb-1"
//                 >
//                   First Name
//                 </label>
//                 <input
//                   id="firstName"
//                   name="firstName"
//                   type="text"
//                   required
//                   className="appearance-none relative block w-full px-3 py-2 border border-[#4a4a4a] rounded-md text-white bg-[#3a3a3a] placeholder-[#6489a2] focus:outline-none focus:ring-2 focus:ring-[#aad5e7] focus:border-transparent transition-colors"
//                   placeholder="John"
//                   value={formData.firstName}
//                   onChange={handleChange}
//                 />
//               </div>
//               <div>
//                 <label
//                   htmlFor="lastName"
//                   className="block text-sm font-medium text-[#aad5e7] mb-1"
//                 >
//                   Last Name
//                 </label>
//                 <input
//                   id="lastName"
//                   name="lastName"
//                   type="text"
//                   required
//                   className="appearance-none relative block w-full px-3 py-2 border border-[#4a4a4a] rounded-md text-white bg-[#3a3a3a] placeholder-[#6489a2] focus:outline-none focus:ring-2 focus:ring-[#aad5e7] focus:border-transparent transition-colors"
//                   placeholder="Doe"
//                   value={formData.lastName}
//                   onChange={handleChange}
//                 />
//               </div>
//             </div>
//             <div>
//   <label
//     htmlFor="phone-number"
//     className="block text-sm font-medium text-[#aad5e7] mb-1"
//   >
//     Phone Number
//   </label>
//   <div className="flex space-x-2 items-center">
//     <span className="text-white bg-[#4a4a4a] px-2 py-2 rounded-md">+1</span>
//     <input
//       id="area-code"
//       name="areaCode"
//       type="text"
//       maxLength="3"
//       required
//       className="appearance-none w-14 px-2 py-2 border border-[#4a4a4a] rounded-md text-white bg-[#3a3a3a] placeholder-[#6489a2] focus:outline-none focus:ring-2 focus:ring-[#aad5e7] focus:border-transparent text-center"
//       placeholder="123"
//       value={formData.areaCode || ""}
//       onChange={(e) => {
//         const { value } = e.target;
//         if (value.length === 3) document.getElementById("central-code").focus();
//         setFormData((prev) => ({ ...prev, areaCode: value }));
//       }}
//     />
//     <input
//       id="central-code"
//       name="centralCode"
//       type="text"
//       maxLength="3"
//       required
//       className="appearance-none w-14 px-2 py-2 border border-[#4a4a4a] rounded-md text-white bg-[#3a3a3a] placeholder-[#6489a2] focus:outline-none focus:ring-2 focus:ring-[#aad5e7] focus:border-transparent text-center"
//       placeholder="456"
//       value={formData.centralCode || ""}
//       onChange={(e) => {
//         const { value } = e.target;
//         if (value.length === 3) document.getElementById("station-code").focus();
//         setFormData((prev) => ({ ...prev, centralCode: value }));
//       }}
//     />
//     <input
//       id="station-code"
//       name="stationCode"
//       type="text"
//       maxLength="4"
//       required
//       className="appearance-none w-16 px-2 py-2 border border-[#4a4a4a] rounded-md text-white bg-[#3a3a3a] placeholder-[#6489a2] focus:outline-none focus:ring-2 focus:ring-[#aad5e7] focus:border-transparent text-center"
//       placeholder="7890"
//       value={formData.stationCode || ""}
//       onChange={(e) => {
//         setFormData((prev) => ({ ...prev, stationCode: e.target.value }));
//       }}
//     />
//   </div>
// </div>
//             <div>
//               <label
//                 htmlFor="email-address"
//                 className="block text-sm font-medium text-[#aad5e7] mb-1"
//               >
//                 Email Address
//               </label>
//               <input
//                 id="email-address"
//                 name="email"
//                 type="email"
//                 autoComplete="email"
//                 required
//                 className="appearance-none relative block w-full px-3 py-2 border border-[#4a4a4a] rounded-md text-white bg-[#3a3a3a] placeholder-[#6489a2] focus:outline-none focus:ring-2 focus:ring-[#aad5e7] focus:border-transparent transition-colors"
//                 placeholder="john@example.com"
//                 value={formData.email}
//                 onChange={handleChange}
//               />
//             </div>

//             <div>
//               <label
//                 htmlFor="password"
//                 className="block text-sm font-medium text-[#aad5e7] mb-1"
//               >
//                 Password
//               </label>
//               <div className="relative">
//                 <input
//                   id="password"
//                   name="password"
//                   type={showPassword ? "text" : "password"}
//                   required
//                   className="appearance-none relative block w-full px-3 py-2 border border-[#4a4a4a] rounded-md text-white bg-[#3a3a3a] placeholder-[#6489a2] focus:outline-none focus:ring-2 focus:ring-[#aad5e7] focus:border-transparent transition-colors"
//                   placeholder="••••••••"
//                   value={formData.password}
//                   onChange={handleChange}
//                 />
//                 <button
//                   type="button"
//                   onClick={() => setShowPassword(!showPassword)}
//                   className="absolute inset-y-0 right-0 pr-3 flex items-center text-[#6489a2] hover:text-[#aad5e7] transition-colors"
//                 >
//                   {showPassword ? (
//                     <EyeOff className="h-5 w-5" />
//                   ) : (
//                     <Eye className="h-5 w-5" />
//                   )}
//                 </button>
//               </div>
//             </div>

//             <div>
//               <label
//                 htmlFor="userType"
//                 className="block text-sm font-medium text-[#aad5e7] mb-1"
//               >
//                 Your Role
//               </label>
//               <select
//                 id="userType"
//                 name="userType"
//                 required
//                 className="appearance-none relative block w-full px-3 py-2 border border-[#4a4a4a] rounded-md text-white bg-[#3a3a3a] placeholder-[#6489a2] focus:outline-none focus:ring-2 focus:ring-[#aad5e7] focus:border-transparent transition-colors"
//                 value={formData.userType}
//                 onChange={handleChange}
//               >
//                 <option value="">Select your role</option>
//                 <option value="BMET">BMET</option>
//                 <option value="Deployment Technician">
//                   Deployment Technician
//                 </option>
//               </select>
//             </div>
//           </div>

//           <div>
//             <button
//               type="submit"
//               disabled={isLoading}
//               className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#6489a2] hover:bg-[#aad5e7] hover:text-[#2a2a2a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#aad5e7] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
//             >
//               {isLoading ? "Creating account..." : "Create Account"}
//             </button>
//           </div>

//           <div className="text-center text-sm">
//             <span className="text-[#6489a2]">Already have an account? </span>
//             <button
//               type="button"
//               onClick={() => navigate("/signin")}
//               className="font-medium text-[#aad5e7] hover:text-white transition-colors"
//             >
//               Sign in
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default SignUp;
