import React from "react";

function ResourceFilterButtons({ selectedView, onViewChange }) {
  return (
    <div className="flex justify-center space-x-4 mb-8">
      <button
        onClick={() => onViewChange("BMET")}
        className={`px-4 py-2 rounded-md ${
          selectedView === "BMET"
            ? "bg-[#6489a2] text-white"
            : "bg-white text-[#6489a2] border border-[#6489a2]"
        }`}
      >
        BMET
      </button>
      <button
        onClick={() => onViewChange("Deployment Technician")}
        className={`px-4 py-2 rounded-md ${
          selectedView === "Deployment Technician"
            ? "bg-[#6489a2] text-white"
            : "bg-white text-[#6489a2] border border-[#6489a2]"
        }`}
      >
        Deployment Tech
      </button>
    </div>
  );
}

export default ResourceFilterButtons;
