import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { auth, db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import TechnicianSidebar from "../components/TechnicianSidebar";
import AvailabilityCalendar from "../components/AvailabilityCalendar";
import ProfileEdit from "../components/ProfileEdit";
import axios from "axios";

function TechnicianDashboard() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState("calendar");
  const [location, setLocation] = useState(null);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const navigate = useNavigate();

  const getGeolocation = () => {
    setLoading(true);
    setError('');
    setCity('');
    setState('');

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          fetchCityAndState(latitude, longitude);
          setLoading(false);
        },
        (error) => {
          setError('Unable to retrieve location.');
          console.error(error.message);
          setLoading(false);
        },
        { enableHighAccuracy: true }
      );
    } else {
      setError('Geolocation is not supported by your browser.');
      setLoading(false);
    }
  };

  const fetchCityAndState = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );
      const results = response.data.results;

      if (results.length > 0) {
        const addressComponents = results[0].address_components;

        const cityComponent = addressComponents.find((component) =>
          component.types.includes('locality')
        );
        const stateComponent = addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1')
        );

        setCity(cityComponent?.long_name || '');
        setState(stateComponent?.short_name || '');
      } else {
        setError('Unable to retrieve address information.');
        console.error('No results found.');
      }
    } catch (err) {
      setError('Error retrieving city and state.');
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const userDoc = await getDoc(doc(db, "technicians", currentUser.uid));
          if (userDoc.exists()) {
            setUser({ id: userDoc.id, ...userDoc.data() });
          } else {
            setError("User document does not exist.");
          }
        } else {
          setError("No authenticated user found.");
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Failed to fetch user data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    getGeolocation();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/signin");
    } catch (error) {
      console.error("Error signing out:", error);
      alert("Failed to sign out. Please try again.");
    }
  };

  const handleProfileUpdate = async () => {
    const currentUser = auth.currentUser;
    try {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, "technicians", currentUser.uid));
        if (userDoc.exists()) {
          let data = userDoc.data();
          if (location) {
            data = { ...data, location };
          }
          if (city) {
            data = { ...data, city };
          }
          if (state) {
            data = { ...data, state };
          }
          setUser(data);
          delete data.id;
          updateDoc(doc(db, "technicians", currentUser.uid), data);
        }
      }
    } catch (error) {
      console.error("Error handleProfileUpdate:", error);
    }
  };

  const handleSectionChange = (section) => {
    if (section === "editExperience") {
      if (user && user.userType) {
        if (user.userType === "BMET") {
          navigate(`/bmet-questionnaire/${user.id}`);
        } else if (user.userType === "Deployment Technician") {
          navigate(`/deployment-questionnaire/${user.id}`);
        }
      } else {
        setError("User type not found. Please update your profile.");
      }
    } else {
      setActiveSection(section);
    }
  };

  if (loading) {
    return <div className="text-center text-gray-600">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  if (!user) {
    return <div className="text-center text-gray-600">No user data available.</div>;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <TechnicianSidebar
        activeSection={activeSection}
        onSectionChange={handleSectionChange}
        onLogout={handleLogout}
      />
      <main className="flex-1 p-6 overflow-auto">
        <h1 className="text-3xl font-bold mb-4 text-blue-600">
          Welcome, {user.firstName} {user.lastName}
        </h1>
        {activeSection === "calendar" && <AvailabilityCalendar />}
        {activeSection === "profile" && (
          <ProfileEdit user={user} onProfileUpdate={handleProfileUpdate} />
        )}
      </main>
    </div>
  );
}

export default TechnicianDashboard;



// import React from "react";
// import { Calendar, User, LogOut, Edit } from 'lucide-react';
// import {
//   Sidebar,
//   SidebarContent,
//   SidebarGroup,
//   SidebarGroupContent,
//   SidebarGroupLabel,
//   SidebarHeader,
//   SidebarMenu,
//   SidebarMenuButton,
//   SidebarMenuItem,
// } from "./Sidebar";

// const TechnicianSidebar = ({ activeSection, onSectionChange, onLogout }) => {
//   return (
//     <Sidebar>
//       <SidebarHeader>
//         <h2 className="text-lg font-semibold text-techmap">
//           Technician Dashboard
//         </h2>
//       </SidebarHeader>
//       <SidebarContent>
//         <SidebarGroup>
//           <SidebarGroupLabel>Menu</SidebarGroupLabel>
//           <SidebarGroupContent>
//             <SidebarMenu>
//               <SidebarMenuItem>
//                 <SidebarMenuButton
//                   onClick={() => onSectionChange("calendar")}
//                   isActive={activeSection === "calendar"}
//                 >
//                   <Calendar className="inline-block mr-2 h-4 w-4" />
//                   <span>Availability Calendar</span>
//                 </SidebarMenuButton>
//               </SidebarMenuItem>
//               <SidebarMenuItem>
//                 <SidebarMenuButton
//                   onClick={() => onSectionChange("profile")}
//                   isActive={activeSection === "profile"}
//                 >
//                   <User className="inline-block mr-2 h-4 w-4" />
//                   <span>Edit Profile</span>
//                 </SidebarMenuButton>
//               </SidebarMenuItem>
//               <SidebarMenuItem>
//                 <SidebarMenuButton
//                   onClick={() => onSectionChange("editExperience")}
//                   isActive={activeSection === "editExperience"}
//                 >
//                   <Edit className="inline-block mr-2 h-4 w-4" />
//                   <span>Edit Experience</span>
//                 </SidebarMenuButton>
//               </SidebarMenuItem>
//               <SidebarMenuItem>
//                 <SidebarMenuButton onClick={onLogout}>
//                   <LogOut className="inline-block mr-2 h-4 w-4" />
//                   <span>Logout</span>
//                 </SidebarMenuButton>
//               </SidebarMenuItem>
//             </SidebarMenu>
//           </SidebarGroupContent>
//         </SidebarGroup>
//       </SidebarContent>
//     </Sidebar>
//   );
// };

// export default TechnicianSidebar;
