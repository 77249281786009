import React, { useState, useEffect, useCallback } from "react";

const AvailabilityCalendar = () => {
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [openToTravel, setOpenToTravel] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dragStart, setDragStart] = useState(null);

  const handleDateClick = useCallback((date) => {
    if (!isEditing) return;
    const dateString = date.toISOString().split("T")[0];
    setUnavailableDates(prev =>
      prev.includes(dateString)
        ? prev.filter(d => d !== dateString)
        : [...prev, dateString]
    );
  }, [isEditing]);

  const handleMouseDown = useCallback((date) => {
    if (!isEditing) return;
    setIsDragging(true);
    setDragStart(date);
    handleDateClick(date);
  }, [isEditing, handleDateClick]);

  const handleMouseEnter = useCallback((date) => {
    if (!isEditing || !isDragging) return;
    handleDateClick(date);
  }, [isEditing, isDragging, handleDateClick]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setDragStart(null);
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseUp]);

  const renderCalendar = (month, year) => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const startingDay = firstDay.getDay();

    const days = [];
    for (let i = 0; i < startingDay; i++) {
      days.push(<div key={`empty-${i}`} className="w-8 h-8 m-1" />);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(year, month, i);
      const dateString = date.toISOString().split("T")[0];
      days.push(
        <button
          key={dateString}
          onMouseDown={() => handleMouseDown(date)}
          onMouseEnter={() => handleMouseEnter(date)}
          className={`w-8 h-8 m-1 rounded-full ${
            unavailableDates.includes(dateString)
              ? "bg-red-500 text-white"
              : "bg-gray-100 text-gray-800 hover:bg-blue-100"
          } ${!isEditing && "cursor-default"}`}
          disabled={!isEditing}
        >
          {i}
        </button>
      );
    }

    return days;
  };

  const renderMonths = () => {
    const months = [];
    for (let i = 0; i < 6; i++) {
      const monthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
      months.push(
        <div key={`month-${i}`} className="mb-8">
          <h3 className="text-lg font-semibold mb-2 text-gray-800">
            {monthDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
          </h3>
          <div className="grid grid-cols-7 gap-1">
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
              <div key={day} className="text-center font-medium text-gray-600">
                {day}
              </div>
            ))}
            {renderCalendar(monthDate.getMonth(), monthDate.getFullYear())}
          </div>
        </div>
      );
    }
    return months;
  };

  const handlePrevious = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, 1));
  };

  const handleNext = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, 1));
  };

  const handleSave = () => {
    setIsEditing(false);
    // Here you would typically save the state to a backend or localStorage
    console.log("Saving availability:", { unavailableDates, openToTravel });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  return (
    <div className="p-6 bg-white text-gray-800">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800">Availability Calendar</h2>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="open-to-travel"
              checked={openToTravel}
              onChange={(e) => setOpenToTravel(e.target.checked)}
              className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
              disabled={!isEditing}
            />
            <label htmlFor="open-to-travel" className="text-gray-700">Open to Travel</label>
          </div>
          {isEditing ? (
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              Save
            </button>
          ) : (
            <button
              onClick={handleEdit}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              Edit
            </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {renderMonths()}
      </div>
      <div className="flex justify-center mt-6 space-x-4">
        <button
          onClick={handlePrevious}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          ← Previous
        </button>
        <button
          onClick={handleNext}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Next →
        </button>
      </div>
    </div>
  );
};

export default AvailabilityCalendar;



// import React, { useState, useEffect, useCallback } from "react";

// const AvailabilityCalendar = () => {
//   const [unavailableDates, setUnavailableDates] = useState([]);
//   const [currentDate, setCurrentDate] = useState(new Date());
//   const [openToTravel, setOpenToTravel] = useState(false);
//   const [isEditing, setIsEditing] = useState(false);
//   const [isDragging, setIsDragging] = useState(false);
//   // eslint-disable-next-line no-unused-vars
//   const [dragStart, setDragStart] = useState(null);

//   const handleDateClick = useCallback((date) => {
//     if (!isEditing) return;
//     const dateString = date.toISOString().split("T")[0];
//     setUnavailableDates(prev =>
//       prev.includes(dateString)
//         ? prev.filter(d => d !== dateString)
//         : [...prev, dateString]
//     );
//   }, [isEditing]);

//   const handleMouseDown = useCallback((date) => {
//     if (!isEditing) return;
//     setIsDragging(true);
//     setDragStart(date);
//     handleDateClick(date);
//   }, [isEditing, handleDateClick]);

//   const handleMouseEnter = useCallback((date) => {
//     if (!isEditing || !isDragging) return;
//     handleDateClick(date);
//   }, [isEditing, isDragging, handleDateClick]);

//   const handleMouseUp = useCallback(() => {
//     setIsDragging(false);
//     setDragStart(null);
//   }, []);

//   useEffect(() => {
//     document.addEventListener('mouseup', handleMouseUp);
//     return () => {
//       document.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, [handleMouseUp]);

//   const renderCalendar = (month, year) => {
//     const firstDay = new Date(year, month, 1);
//     const lastDay = new Date(year, month + 1, 0);
//     const daysInMonth = lastDay.getDate();
//     const startingDay = firstDay.getDay();

//     const days = [];
//     for (let i = 0; i < startingDay; i++) {
//       days.push(<div key={`empty-${i}`} className="w-8 h-8 m-1" />);
//     }

//     for (let i = 1; i <= daysInMonth; i++) {
//       const date = new Date(year, month, i);
//       const dateString = date.toISOString().split("T")[0];
//       days.push(
//         <button
//           key={dateString}
//           onMouseDown={() => handleMouseDown(date)}
//           onMouseEnter={() => handleMouseEnter(date)}
//           className={`w-8 h-8 m-1 rounded-full ${unavailableDates.includes(dateString)
//               ? "bg-red-600 text-white"
//               : "bg-gray-700 text-[#aad5e7] hover:bg-[#6489a2] hover:text-white"
//             } ${!isEditing && "cursor-default"}`}
//           disabled={!isEditing}
//         >
//           {i}
//         </button>
//       );
//     }

//     return days;
//   };

//   const renderMonths = () => {
//     const months = [];
//     for (let i = 0; i < 6; i++) {
//       const monthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
//       months.push(
//         <div key={`month-${i}`} className="mb-8">
//           <h3 className="text-lg font-semibold mb-2 text-[#aad5e7]">
//             {monthDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
//           </h3>
//           <div className="grid grid-cols-7 gap-1">
//             {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
//               <div key={day} className="text-center font-medium text-[#6489a2]">
//                 {day}
//               </div>
//             ))}
//             {renderCalendar(monthDate.getMonth(), monthDate.getFullYear())}
//           </div>
//         </div>
//       );
//     }
//     return months;
//   };

//   const handlePrevious = () => {
//     setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, 1));
//   };

//   const handleNext = () => {
//     setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, 1));
//   };

//   const handleSave = () => {
//     setIsEditing(false);
//     // Here you would typically save the state to a backend or localStorage
//     console.log("Saving availability:", { unavailableDates, openToTravel });
//   };

//   const handleEdit = () => {
//     setIsEditing(true);
//   };

//   return (
//     <div className="p-6 bg-gray-900 text-[#aad5e7]">
//       <div className="flex justify-between items-center mb-6">
//         <h2 className="text-3xl font-bold text-[#aad5e7]">Availability Calendar</h2>
//         <div className="flex items-center space-x-4">
//           <div className="flex items-center space-x-2">
//             <input
//               type="checkbox"
//               id="open-to-travel"
//               checked={openToTravel}
//               onChange={(e) => setOpenToTravel(e.target.checked)}
//               className="form-checkbox h-5 w-5 text-[#6489a2] bg-gray-700 border-gray-600"
//               disabled={!isEditing}
//             />
//             <label htmlFor="open-to-travel" className="text-[#aad5e7]">Open to Travel</label>
//           </div>
//           {isEditing ? (
//             <button
//               onClick={handleSave}
//               className="px-4 py-2 bg-[#6489a2] text-white rounded hover:bg-[#aad5e7]"
//             >
//               Save
//             </button>
//           ) : (
//             <button
//               onClick={handleEdit}
//               className="px-4 py-2 bg-[#6489a2] text-white rounded hover:bg-[#aad5e7]"
//             >
//               Edit
//             </button>
//           )}
//         </div>
//       </div>
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//         {renderMonths()}
//       </div>
//       <div className="flex justify-center mt-6 space-x-4">
//         <button
//           onClick={handlePrevious}
//           className="px-4 py-2 bg-[#6489a2] text-white rounded hover:bg-[#aad5e7]"
//         >
//           ← Previous
//         </button>
//         <button
//           onClick={handleNext}
//           className="px-4 py-2 bg-[#6489a2] text-white rounded hover:bg-[#aad5e7]"
//         >
//           Next →
//         </button>
//       </div>
//     </div>
//   );
// };

// export default AvailabilityCalendar;

