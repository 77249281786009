import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";
import ResourceFilterButtons from "../components/ResourceFilterButtons";
import Sponsors from "../components/Sponsors";
import { useNavigate } from "react-router-dom";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 39.8283,
  lng: -98.5795,
};

// Updated map styles for light mode
const mapStyles = [
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [{ color: "#f9f9f9" }]
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#e9e9e9" }]
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{ color: "#f5f5f5" }]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }]
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{ color: "#e5e5e5" }]
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#f2f2f2" }]
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ color: "#333333" }]
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [{ color: "#cccccc" }]
  }
];

const deploymentProjects = [
  "All Projects",
  "Win10 to Win11",
  "Epic Readiness & Refresh",
  "Epic Cutover / Go-live Support",
  "IT Construction / New Hospital Build-out",
];

const states = [
  { name: "All States", abbreviation: "" },
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" },
];

const bmetEquipmentCategories = {
  "General Biomedical Equipment (A - I)": [
    "Air/Oxygen Blenders",
    "Apheresis Devices",
    "Beds",
    "Bladder Scanners",
    "Continuous Cardiac Output Monitors",
    "Defibrillators/AED Defibs",
    "Dopplers",
    "EKG Machines",
    "Feeding Pump - Kangaroo",
    "Fetal Monitors",
    "Fluid Warmer",
    "Gym Equipment",
    "Hypo/Hyperthermia Machines",
    "Infant Incubators",
    "Infant Ventilator",
    "Infant Warmer",
    "Monitors, Physiological",
    "Nurse Call Systems",
    "Ophthalmoscope/Otoscope",
    "Patient Lifts",
    "PCA/Epidural Pumps",
    "Phototherapy Lights",
    "Pulse Oximeter",
    "Sequential Compression Device",
    "Sphygmometer, Electronic",
    "Telemetry Transmitters",
    "Thermometers, Electronic, Oral/Temporal",
    "Ventilator",
    "Warming Blanket / Bear Hugger"
  ],
  "Operating Room (OR)": [
    "Ablation",
    "Argon Beam Coagulators",
    "Balloon Pumps",
    "Electrosurgical Unit",
    "Pacemaker, External A/V Sequential",
    "Pumps, IV",
    "Suction, Portable",
    "Syringe Pumps",
    "Video Towers/Printers/Light Sources"
  ],
  "Radiology": [
    "Ultrasound",
    "PET/CT",
    "MRI",
    "X-Ray",
    "Mammography",
    "Cath Lab/IR"
  ],
  "Dialysis": [
    "CVVH Hemodialysis Machines",
    "Dialysis Machine"
  ]
};

function Home() {
  const [technicians, setTechnicians] = useState([]);
  const [filteredTechnicians, setFilteredTechnicians] = useState([]);
  const [selectedView, setSelectedView] = useState("BMET");
  const [isLoading, setIsLoading] = useState(true);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [selectedProject, setSelectedProject] = useState("All Projects");
  const [selectedBmetCategory, setSelectedBmetCategory] = useState("");
  const [selectedBmetSubcategory, setSelectedBmetSubcategory] = useState("");
  const [geolocationError, setGeolocationError] = useState(null); // Added state for geolocation errors
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTechnicians = async () => {
      setIsLoading(true);
      try {
        const technicianData = [];
        const querySnapshot = await getDocs(collection(db, "technicians"));
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.location && data.location.lat && data.location.lng) {
            technicianData.push({ id: doc.id, ...data });
          }
        });
        console.log("🚀 ~ technicianData:", technicianData);
        console.log("Fetched technicians:", technicianData.map(t => ({
          id: t.id,
          userType: t.userType,
          location: t.location
        })));
        setFilteredTechnicians(technicianData);
        setTechnicians(technicianData);
      } catch (error) {
        console.error("Error fetching technicians:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTechnicians();
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
          let errorMessage = "";
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = "Location access was denied. Please enable location services to see nearby technicians.";
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = "Location information is unavailable. Using default map view.";
              break;
            case error.TIMEOUT:
              errorMessage = "Location request timed out. Using default map view.";
              break;
            default:
              errorMessage = "An unknown error occurred. Using default map view.";
              break;
          }
          setGeolocationError(errorMessage);
          setUserLocation(center); // Use the default center as fallback
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setUserLocation(center); // Use the default center as fallback
    }
  }, []);

  useEffect(() => {
    filterTechnicians();
  }, [selectedStates, selectedView, selectedProject, selectedBmetCategory, selectedBmetSubcategory, technicians]);

  const getStateName = (stateAbbreviation) => {
    const state = states.find((s) => s.abbreviation === stateAbbreviation);
    return state ? state.name : "";
  };

  const handleStateSelection = (event) => {
    const state = event.target.value;
    if (state === "All States") {
      setSelectedStates([]);
    } else {
      setSelectedStates((prev) => {
        if (prev.includes(state)) {
          return prev.filter((s) => s !== state);
        } else {
          return [...prev.filter((s) => s !== "All States"), state];
        }
      });
    }
  };

  const handleProjectSelection = (event) => {
    setSelectedProject(event.target.value);
  };

  const handleBmetCategorySelection = (event) => {
    setSelectedBmetCategory(event.target.value);
    setSelectedBmetSubcategory("");
  };

  const handleBmetSubcategorySelection = (event) => {
    setSelectedBmetSubcategory(event.target.value);
  };

  const filterTechnicians = () => {
    let filtered = technicians;

    if (selectedStates.length > 0) {
      filtered = filtered.filter((tech) =>
        selectedStates.includes(getStateName(tech.state))
      );
    }

    if (selectedView === "BMET") {
      filtered = filtered.filter((tech) => tech.userType === "BMET");
      if (selectedBmetCategory && selectedBmetSubcategory) {
        filtered = filtered.filter((tech) =>
          tech.bmetEquipment &&
          tech.bmetEquipment.category === selectedBmetCategory &&
          tech.bmetEquipment.subcategory === selectedBmetSubcategory
        );
      }
    } else if (selectedView === "Deployment Technician") {
      filtered = filtered.filter((tech) => tech.userType === "Deployment Technician");
      if (selectedProject !== "All Projects") {
        filtered = filtered.filter((tech) =>
          tech.deploymentProjects && tech.deploymentProjects.includes(selectedProject)
        );
      }
    }

    setFilteredTechnicians(filtered);
  };

  const handleMarkerClick = (technician) => {
    setSelectedTechnician(technician);
  };

  const handleInfoWindowClose = () => {
    setSelectedTechnician(null);
  };

  const handleBookResource = (technicianId) => {
    navigate(`/resource-request-form?technicianId=${technicianId}`);
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">
          Connect with Technical Resources
        </h1>
        <p className="text-xl text-center text-gray-600 mb-8">
          Find biomed technical resources in seconds - not days, weeks, or months.
        </p>
        <div className="flex flex-col items-center mb-4">
          <div className="flex justify-center mb-4">
            <ResourceFilterButtons
              selectedView={selectedView}
              onViewChange={setSelectedView}
            />
          </div>
          <div className="flex justify-center space-x-4">
            <div className="w-48">
              <select
                onChange={handleStateSelection}
                value={selectedStates.length === 0 ? "All States" : selectedStates[0]}
                className="w-full bg-white border border-gray-300 text-gray-700 rounded-md shadow-sm p-2"
              >
                {states.map((state) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            {selectedView === "Deployment Technician" && (
              <div className="w-48">
                <select
                  onChange={handleProjectSelection}
                  value={selectedProject}
                  className="w-full bg-white border border-gray-300 text-gray-700 rounded-md shadow-sm p-2"
                >
                  {deploymentProjects.map((project) => (
                    <option key={project} value={project}>
                      {project}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {selectedView === "BMET" && (
              <>
                <div className="w-48">
                  <select
                    onChange={handleBmetCategorySelection}
                    value={selectedBmetCategory}
                    className="w-full bg-white border border-gray-300 text-gray-700 rounded-md shadow-sm p-2"
                  >
                    <option value="">All BMET Equipment</option>
                    {Object.keys(bmetEquipmentCategories).map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedBmetCategory && (
                  <div className="w-48">
                    <select
                      onChange={handleBmetSubcategorySelection}
                      value={selectedBmetSubcategory}
                      className="w-full bg-white border border-gray-300 text-gray-700 rounded-md shadow-sm p-2"
                    >
                      <option value="">All Subcategories</option>
                      {bmetEquipmentCategories[selectedBmetCategory].map((subcategory) => (
                        <option key={subcategory} value={subcategory}>
                          {subcategory}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-2 mb-4">
          {selectedStates.length === 0 ? (
            <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded">
              All States
            </span>
          ) : (
            selectedStates.map((state) => (
              <span key={state} className="bg-gray-200 text-gray-700 px-2 py-1 rounded flex items-center">
                {state}
                <button
                  onClick={() => handleStateSelection({ target: { value: state } })}
                  className="ml-2 text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </span>
            ))
          )}
        </div>
        {geolocationError && (
          <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
            <p>{geolocationError}</p>
          </div>
        )} {/* Added conditional rendering for geolocation error */}
        {isLoading && (
          <p className="text-center text-gray-600">
            Loading technicians...
          </p>
        )}
        {!isLoading && (
          <div className="rounded-lg overflow-hidden shadow-lg">
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={userLocation || center}
                zoom={userLocation ? 10 : 4}
                options={{ styles: mapStyles }}
              >
                {filteredTechnicians.map((technician) =>
                  technician.location &&
                  technician.location.lat &&
                  technician.location.lng && (
                    <Marker
                      key={technician.id}
                      position={{
                        lat: technician.location.lat,
                        lng: technician.location.lng,
                      }}
                      onClick={() => handleMarkerClick(technician)}
                      icon={{
                        url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                      }}
                    />
                  )
                )}
                {selectedTechnician && (
                  <InfoWindow
                    position={{
                      lat: selectedTechnician.location.lat,
                      lng: selectedTechnician.location.lng,
                    }}
                    onCloseClick={handleInfoWindowClose}
                  >
                    <div className="bg-white p-4 rounded-lg shadow-md">
                      <h3 className="text-lg font-semibold mb-2 text-gray-800">{selectedTechnician.userType}</h3>
                      <button
                        onClick={() => handleBookResource(selectedTechnician.id)}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
                      >
                        Book Resource
                      </button>
                    </div>
                  </InfoWindow>
                )}
                {userLocation && selectedView === "BMET" && (
                  <Marker
                    position={userLocation}
                    title="Your Location"
                    icon={{
                      url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                    }}
                  />
                )}
              </GoogleMap>
            </LoadScript>
          </div>
        )}
      </div>
      <Sponsors />
    </div>
  );
}

export default Home;



// import React, { useState, useEffect } from "react";
// import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
// import { collection, getDocs } from "firebase/firestore";
// import { db } from "../config/firebase";
// import ResourceFilterButtons from "../components/ResourceFilterButtons";
// import Sponsors from "../components/Sponsors";
// import { useNavigate } from "react-router-dom";

// const mapContainerStyle = {
//   width: "100%",
//   height: "400px",
// };

// const center = {
//   lat: 39.8283,
//   lng: -98.5795,
// };

// // Updated map styles for light mode
// const mapStyles = [
//   {
//     featureType: "all",
//     elementType: "geometry.fill",
//     stylers: [{ color: "#f9f9f9" }]
//   },
//   {
//     featureType: "water",
//     elementType: "geometry",
//     stylers: [{ color: "#e9e9e9" }]
//   },
//   {
//     featureType: "landscape",
//     elementType: "geometry",
//     stylers: [{ color: "#f5f5f5" }]
//   },
//   {
//     featureType: "road",
//     elementType: "geometry",
//     stylers: [{ color: "#ffffff" }]
//   },
//   {
//     featureType: "poi",
//     elementType: "geometry",
//     stylers: [{ color: "#e5e5e5" }]
//   },
//   {
//     featureType: "transit",
//     elementType: "geometry",
//     stylers: [{ color: "#f2f2f2" }]
//   },
//   {
//     elementType: "labels.text.fill",
//     stylers: [{ color: "#333333" }]
//   },
//   {
//     featureType: "administrative",
//     elementType: "geometry.stroke",
//     stylers: [{ color: "#cccccc" }]
//   }
// ];

// const deploymentProjects = [
//   "All Projects",
//   "Win10 to Win11",
//   "Epic Readiness & Refresh",
//   "Epic Cutover / Go-live Support",
//   "IT Construction / New Hospital Build-out",
// ];

// const states = [
//   { name: "All States", abbreviation: "" },
//   { name: "Alabama", abbreviation: "AL" },
//   { name: "Alaska", abbreviation: "AK" },
//   { name: "Arizona", abbreviation: "AZ" },
//   { name: "Arkansas", abbreviation: "AR" },
//   { name: "California", abbreviation: "CA" },
//   { name: "Colorado", abbreviation: "CO" },
//   { name: "Connecticut", abbreviation: "CT" },
//   { name: "Delaware", abbreviation: "DE" },
//   { name: "Florida", abbreviation: "FL" },
//   { name: "Georgia", abbreviation: "GA" },
//   { name: "Hawaii", abbreviation: "HI" },
//   { name: "Idaho", abbreviation: "ID" },
//   { name: "Illinois", abbreviation: "IL" },
//   { name: "Indiana", abbreviation: "IN" },
//   { name: "Iowa", abbreviation: "IA" },
//   { name: "Kansas", abbreviation: "KS" },
//   { name: "Kentucky", abbreviation: "KY" },
//   { name: "Louisiana", abbreviation: "LA" },
//   { name: "Maine", abbreviation: "ME" },
//   { name: "Maryland", abbreviation: "MD" },
//   { name: "Massachusetts", abbreviation: "MA" },
//   { name: "Michigan", abbreviation: "MI" },
//   { name: "Minnesota", abbreviation: "MN" },
//   { name: "Mississippi", abbreviation: "MS" },
//   { name: "Missouri", abbreviation: "MO" },
//   { name: "Montana", abbreviation: "MT" },
//   { name: "Nebraska", abbreviation: "NE" },
//   { name: "Nevada", abbreviation: "NV" },
//   { name: "New Hampshire", abbreviation: "NH" },
//   { name: "New Jersey", abbreviation: "NJ" },
//   { name: "New Mexico", abbreviation: "NM" },
//   { name: "New York", abbreviation: "NY" },
//   { name: "North Carolina", abbreviation: "NC" },
//   { name: "North Dakota", abbreviation: "ND" },
//   { name: "Ohio", abbreviation: "OH" },
//   { name: "Oklahoma", abbreviation: "OK" },
//   { name: "Oregon", abbreviation: "OR" },
//   { name: "Pennsylvania", abbreviation: "PA" },
//   { name: "Rhode Island", abbreviation: "RI" },
//   { name: "South Carolina", abbreviation: "SC" },
//   { name: "South Dakota", abbreviation: "SD" },
//   { name: "Tennessee", abbreviation: "TN" },
//   { name: "Texas", abbreviation: "TX" },
//   { name: "Utah", abbreviation: "UT" },
//   { name: "Vermont", abbreviation: "VT" },
//   { name: "Virginia", abbreviation: "VA" },
//   { name: "Washington", abbreviation: "WA" },
//   { name: "West Virginia", abbreviation: "WV" },
//   { name: "Wisconsin", abbreviation: "WI" },
//   { name: "Wyoming", abbreviation: "WY" },
// ];

// const bmetEquipmentCategories = {
//   "General Biomedical Equipment (A - I)": [
//     "Air/Oxygen Blenders",
//     "Apheresis Devices",
//     "Beds",
//     "Bladder Scanners",
//     "Continuous Cardiac Output Monitors",
//     "Defibrillators/AED Defibs",
//     "Dopplers",
//     "EKG Machines",
//     "Feeding Pump - Kangaroo",
//     "Fetal Monitors",
//     "Fluid Warmer",
//     "Gym Equipment",
//     "Hypo/Hyperthermia Machines",
//     "Infant Incubators",
//     "Infant Ventilator",
//     "Infant Warmer",
//     "Monitors, Physiological",
//     "Nurse Call Systems",
//     "Ophthalmoscope/Otoscope",
//     "Patient Lifts",
//     "PCA/Epidural Pumps",
//     "Phototherapy Lights",
//     "Pulse Oximeter",
//     "Sequential Compression Device",
//     "Sphygmometer, Electronic",
//     "Telemetry Transmitters",
//     "Thermometers, Electronic, Oral/Temporal",
//     "Ventilator",
//     "Warming Blanket / Bear Hugger"
//   ],
//   "Operating Room (OR)": [
//     "Ablation",
//     "Argon Beam Coagulators",
//     "Balloon Pumps",
//     "Electrosurgical Unit",
//     "Pacemaker, External A/V Sequential",
//     "Pumps, IV",
//     "Suction, Portable",
//     "Syringe Pumps",
//     "Video Towers/Printers/Light Sources"
//   ],
//   "Radiology": [
//     "Ultrasound",
//     "PET/CT",
//     "MRI",
//     "X-Ray",
//     "Mammography",
//     "Cath Lab/IR"
//   ],
//   "Dialysis": [
//     "CVVH Hemodialysis Machines",
//     "Dialysis Machine"
//   ]
// };

// function Home() {
//   const [technicians, setTechnicians] = useState([]);
//   const [filteredTechnicians, setFilteredTechnicians] = useState([]);
//   const [selectedView, setSelectedView] = useState("BMET");
//   const [isLoading, setIsLoading] = useState(true);
//   const [userLocation, setUserLocation] = useState(null);
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [selectedTechnician, setSelectedTechnician] = useState(null);
//   const [selectedProject, setSelectedProject] = useState("All Projects");
//   const [selectedBmetCategory, setSelectedBmetCategory] = useState("");
//   const [selectedBmetSubcategory, setSelectedBmetSubcategory] = useState("");
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchTechnicians = async () => {
//       setIsLoading(true);
//       try {
//         const technicianData = [];
//         const querySnapshot = await getDocs(collection(db, "technicians"));
//         querySnapshot.forEach((doc) => {
//           const data = doc.data();
//           if (data.location && data.location.lat && data.location.lng) {
//             technicianData.push({ id: doc.id, ...data });
//           }
//         });
//         console.log("🚀 ~ technicianData:", technicianData);
//         console.log("Fetched technicians:", technicianData.map(t => ({
//           id: t.id,
//           userType: t.userType,
//           location: t.location
//         })));
//         setFilteredTechnicians(technicianData);
//         setTechnicians(technicianData);
//       } catch (error) {
//         console.error("Error fetching technicians:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchTechnicians();
//   }, []);

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setUserLocation({ lat: latitude, lng: longitude });
//         },
//         (error) => {
//           console.error("Error getting user location:", error);
//         },
//         { enableHighAccuracy: true }
//       );
//     } else {
//       console.error("Geolocation is not supported by this browser.");
//     }
//   }, []);

//   useEffect(() => {
//     filterTechnicians();
//   }, [selectedStates, selectedView, selectedProject, selectedBmetCategory, selectedBmetSubcategory, technicians]);

//   const getStateName = (stateAbbreviation) => {
//     const state = states.find((s) => s.abbreviation === stateAbbreviation);
//     return state ? state.name : "";
//   };

//   const handleStateSelection = (event) => {
//     const state = event.target.value;
//     if (state === "All States") {
//       setSelectedStates([]);
//     } else {
//       setSelectedStates((prev) => {
//         if (prev.includes(state)) {
//           return prev.filter((s) => s !== state);
//         } else {
//           return [...prev.filter((s) => s !== "All States"), state];
//         }
//       });
//     }
//   };

//   const handleProjectSelection = (event) => {
//     setSelectedProject(event.target.value);
//   };

//   const handleBmetCategorySelection = (event) => {
//     setSelectedBmetCategory(event.target.value);
//     setSelectedBmetSubcategory("");
//   };

//   const handleBmetSubcategorySelection = (event) => {
//     setSelectedBmetSubcategory(event.target.value);
//   };

//   const filterTechnicians = () => {
//     let filtered = technicians;

//     if (selectedStates.length > 0) {
//       filtered = filtered.filter((tech) =>
//         selectedStates.includes(getStateName(tech.state))
//       );
//     }

//     if (selectedView === "BMET") {
//       filtered = filtered.filter((tech) => tech.userType === "BMET");
//       if (selectedBmetCategory && selectedBmetSubcategory) {
//         filtered = filtered.filter((tech) =>
//           tech.bmetEquipment &&
//           tech.bmetEquipment.category === selectedBmetCategory &&
//           tech.bmetEquipment.subcategory === selectedBmetSubcategory
//         );
//       }
//     } else if (selectedView === "Deployment Technician") {
//       filtered = filtered.filter((tech) => tech.userType === "Deployment Technician");
//       if (selectedProject !== "All Projects") {
//         filtered = filtered.filter((tech) =>
//           tech.deploymentProjects && tech.deploymentProjects.includes(selectedProject)
//         );
//       }
//     }

//     setFilteredTechnicians(filtered);
//   };

//   const handleMarkerClick = (technician) => {
//     setSelectedTechnician(technician);
//   };

//   const handleInfoWindowClose = () => {
//     setSelectedTechnician(null);
//   };

//   const handleBookResource = (technicianId) => {
//     navigate(`/resource-request-form?technicianId=${technicianId}`);
//   };

//   return (
//     <div className="min-h-screen bg-white">
//       <div className="container mx-auto px-4 py-8">
//         <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">
//           Connect with Technical Resources
//         </h1>
//         <p className="text-xl text-center text-gray-600 mb-8">
//           Find biomed technical resources in seconds - not days, weeks, or months.
//         </p>
//         <div className="flex flex-col items-center mb-4">
//           <div className="flex justify-center mb-4">
//             <ResourceFilterButtons
//               selectedView={selectedView}
//               onViewChange={setSelectedView}
//             />
//           </div>
//           <div className="flex justify-center space-x-4">
//             <div className="w-48">
//               <select
//                 onChange={handleStateSelection}
//                 value={selectedStates.length === 0 ? "All States" : selectedStates[0]}
//                 className="w-full bg-white border border-gray-300 text-gray-700 rounded-md shadow-sm p-2"
//               >
//                 {states.map((state) => (
//                   <option key={state.name} value={state.name}>
//                     {state.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             {selectedView === "Deployment Technician" && (
//               <div className="w-48">
//                 <select
//                   onChange={handleProjectSelection}
//                   value={selectedProject}
//                   className="w-full bg-white border border-gray-300 text-gray-700 rounded-md shadow-sm p-2"
//                 >
//                   {deploymentProjects.map((project) => (
//                     <option key={project} value={project}>
//                       {project}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//             {selectedView === "BMET" && (
//               <>
//                 <div className="w-48">
//                   <select
//                     onChange={handleBmetCategorySelection}
//                     value={selectedBmetCategory}
//                     className="w-full bg-white border border-gray-300 text-gray-700 rounded-md shadow-sm p-2"
//                   >
//                     <option value="">All BMET Equipment</option>
//                     {Object.keys(bmetEquipmentCategories).map((category) => (
//                       <option key={category} value={category}>
//                         {category}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//                 {selectedBmetCategory && (
//                   <div className="w-48">
//                     <select
//                       onChange={handleBmetSubcategorySelection}
//                       value={selectedBmetSubcategory}
//                       className="w-full bg-white border border-gray-300 text-gray-700 rounded-md shadow-sm p-2"
//                     >
//                       <option value="">All Subcategories</option>
//                       {bmetEquipmentCategories[selectedBmetCategory].map((subcategory) => (
//                         <option key={subcategory} value={subcategory}>
//                           {subcategory}
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//         <div className="flex flex-wrap justify-center gap-2 mb-4">
//           {selectedStates.length === 0 ? (
//             <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded">
//               All States
//             </span>
//           ) : (
//             selectedStates.map((state) => (
//               <span key={state} className="bg-gray-200 text-gray-700 px-2 py-1 rounded flex items-center">
//                 {state}
//                 <button
//                   onClick={() => handleStateSelection({ target: { value: state } })}
//                   className="ml-2 text-gray-500 hover:text-gray-700"
//                 >
//                   ×
//                 </button>
//               </span>
//             ))
//           )}
//         </div>
//         {isLoading && (
//           <p className="text-center text-gray-600">
//             Loading technicians...
//           </p>
//         )}
//         {!isLoading && (
//           <div className="rounded-lg overflow-hidden shadow-lg">
//             <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
//               <GoogleMap
//                 mapContainerStyle={mapContainerStyle}
//                 center={userLocation || center}
//                 zoom={userLocation ? 10 : 4}
//                 options={{ styles: mapStyles }}
//               >
//                 {filteredTechnicians.map((technician) =>
//                   technician.location &&
//                   technician.location.lat &&
//                   technician.location.lng && (
//                     <Marker
//                       key={technician.id}
//                       position={{
//                         lat: technician.location.lat,
//                         lng: technician.location.lng,
//                       }}
//                       onClick={() => handleMarkerClick(technician)}
//                       icon={{
//                         url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
//                       }}
//                     />
//                   )
//                 )}
//                 {selectedTechnician && (
//                   <InfoWindow
//                     position={{
//                       lat: selectedTechnician.location.lat,
//                       lng: selectedTechnician.location.lng,
//                     }}
//                     onCloseClick={handleInfoWindowClose}
//                   >
//                     <div className="bg-white p-4 rounded-lg shadow-md">
//                       <h3 className="text-lg font-semibold mb-2 text-gray-800">{selectedTechnician.userType}</h3>
//                       <button
//                         onClick={() => handleBookResource(selectedTechnician.id)}
//                         className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
//                       >
//                         Book Resource
//                       </button>
//                     </div>
//                   </InfoWindow>
//                 )}
//                 {userLocation && selectedView === "BMET" && (
//                   <Marker
//                     position={userLocation}
//                     title="Your Location"
//                     icon={{
//                       url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
//                     }}
//                   />
//                 )}
//               </GoogleMap>
//             </LoadScript>
//           </div>
//         )}
//       </div>
//       <Sponsors />
//     </div>
//   );
// }

// export default Home;


