import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../config/firebase";

const ProfileEdit = ({ user, onProfileUpdate }) => {
  const [formData, setFormData] = useState({
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    city: user.city || "",
    state: user.state || "",
    newProjects: user.newProjects || "",
  });
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const currentUser = auth.currentUser;
      await updateDoc(doc(db, "technicians", currentUser.uid), formData);
      onProfileUpdate();
      setIsEditing(false);
      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    }
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="p-6 bg-white text-gray-800 max-w-md mx-auto rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-blue-600">Edit Profile</h2>
      <form className="space-y-4">
        {[
          { name: "firstName", label: "First Name", type: "text" },
          { name: "lastName", label: "Last Name", type: "text" },
          { name: "email", label: "Email", type: "email" },
          { name: "city", label: "City", type: "text" },
          { name: "state", label: "State", type: "text" },
        ].map((field) => (
          <div key={field.name}>
            <label
              htmlFor={field.name}
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              {field.label}
            </label>
            <input
              id={field.name}
              name={field.name}
              type={field.type}
              value={formData[field.name]}
              onChange={handleChange}
              required
              disabled={!isEditing}
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:opacity-50"
            />
          </div>
        ))}
        <div>
          <label
            htmlFor="newProjects"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            New or Other Projects
          </label>
          <textarea
            id="newProjects"
            name="newProjects"
            value={formData.newProjects}
            onChange={handleChange}
            rows="4"
            disabled={!isEditing}
            className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:opacity-50"
          />
        </div>
        {isEditing ? (
          <button
            type="button"
            onClick={handleSubmit}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
          >
            Save Profile
          </button>
        ) : (
          <button
            type="button"
            onClick={handleEdit}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
          >
            Edit Profile
          </button>
        )}
      </form>
    </div>
  );
};

export default ProfileEdit;





// import React, { useState } from "react";
// import { doc, updateDoc } from "firebase/firestore";
// import { auth, db } from "../config/firebase";

// const ProfileEdit = ({ user, onProfileUpdate }) => {
//   const [formData, setFormData] = useState({
//     firstName: user.firstName || "",
//     lastName: user.lastName || "",
//     email: user.email || "",
//     city: user.city || "",
//     state: user.state || "",
//     newProjects: user.newProjects || "",
//   });
//   const [isEditing, setIsEditing] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const currentUser = auth.currentUser;
//       await updateDoc(doc(db, "technicians", currentUser.uid), formData);
//       onProfileUpdate();
//       setIsEditing(false);
//       alert("Profile updated successfully!");
//     } catch (error) {
//       console.error("Error updating profile:", error);
//       alert("Failed to update profile. Please try again.");
//     }
//   };

//   const handleEdit = () => {
//     setIsEditing(!isEditing);
//   };

//   return (
//     <div className="p-6 bg-gray-900 text-[#aad5e7] max-w-md mx-auto">
//       <h2 className="text-3xl font-bold mb-6 text-[#aad5e7]">Edit Profile</h2>
//       <form className="space-y-4">
//         {[
//           { name: "firstName", label: "First Name", type: "text" },
//           { name: "lastName", label: "Last Name", type: "text" },
//           { name: "email", label: "Email", type: "email" },
//           { name: "city", label: "City", type: "text" },
//           { name: "state", label: "State", type: "text" },
//         ].map((field) => (
//           <div key={field.name}>
//             <label
//               htmlFor={field.name}
//               className="block text-sm font-medium text-[#6489a2] mb-2"
//             >
//               {field.label}
//             </label>
//             <input
//               id={field.name}
//               name={field.name}
//               type={field.type}
//               value={formData[field.name]}
//               onChange={handleChange}
//               required
//               disabled={!isEditing}
//               className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm text-[#aad5e7] focus:outline-none focus:ring-2 focus:ring-[#6489a2] focus:border-transparent disabled:opacity-50"
//             />
//           </div>
//         ))}
//         <div>
//           <label
//             htmlFor="newProjects"
//             className="block text-sm font-medium text-[#6489a2] mb-2"
//           >
//             New or Other Projects
//           </label>
//           <textarea
//             id="newProjects"
//             name="newProjects"
//             value={formData.newProjects}
//             onChange={handleChange}
//             rows="4"
//             disabled={!isEditing}
//             className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm text-[#aad5e7] focus:outline-none focus:ring-2 focus:ring-[#6489a2] focus:border-transparent disabled:opacity-50"
//           />
//         </div>
//         {isEditing ? (
//           <button
//             type="button"
//             onClick={handleSubmit}
//             className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#6489a2] hover:bg-[#aad5e7] hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#6489a2] transition duration-150 ease-in-out"
//           >
//             Save Profile
//           </button>
//         ) : (
//           <button
//             type="button"
//             onClick={handleEdit}
//             className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#6489a2] hover:bg-[#aad5e7] hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#6489a2] transition duration-150 ease-in-out"
//           >
//             Edit Profile
//           </button>
//         )}
//       </form>
//     </div>
//   );
// };

// export default ProfileEdit;
