import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-gray-100 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
          <nav className="mb-4">
            <ul className="flex space-x-4">
              <li>
                <Link
                  to="/about"
                  className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/faq"
                  className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
                >
                  FAQs
                </Link>
              </li>
            </ul>
          </nav>
          <p className="text-center text-sm text-gray-600">
            © {new Date().getFullYear()} Made with Clark Technology Ventures,
            LLC.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

