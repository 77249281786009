import React from "react";
import { Calendar, User, LogOut, Edit } from 'lucide-react';

const TechnicianSidebar = ({ activeSection, onSectionChange, onLogout }) => {
  return (
    <div className="w-64 bg-white shadow-md h-screen">
      <div className="p-4">
        <h2 className="text-xl font-semibold text-blue-600 mb-6">
          Technician Dashboard
        </h2>
        <nav>
          <ul className="space-y-2">
            <li>
              <button
                onClick={() => onSectionChange("calendar")}
                className={`w-full text-left px-4 py-2 rounded transition-colors ${
                  activeSection === "calendar"
                    ? "bg-blue-100 text-blue-700"
                    : "text-gray-700 hover:bg-gray-100"
                }`}
              >
                <Calendar className="inline-block mr-2 h-5 w-5" />
                <span>Availability Calendar</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => onSectionChange("profile")}
                className={`w-full text-left px-4 py-2 rounded transition-colors ${
                  activeSection === "profile"
                    ? "bg-blue-100 text-blue-700"
                    : "text-gray-700 hover:bg-gray-100"
                }`}
              >
                <User className="inline-block mr-2 h-5 w-5" />
                <span>Edit Profile</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => onSectionChange("editExperience")}
                className={`w-full text-left px-4 py-2 rounded transition-colors ${
                  activeSection === "editExperience"
                    ? "bg-blue-100 text-blue-700"
                    : "text-gray-700 hover:bg-gray-100"
                }`}
              >
                <Edit className="inline-block mr-2 h-5 w-5" />
                <span>Edit Experience</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div className="absolute bottom-0 w-64 p-4">
        <button
          onClick={onLogout}
          className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 rounded transition-colors"
        >
          <LogOut className="inline-block mr-2 h-5 w-5" />
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default TechnicianSidebar;

